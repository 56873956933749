export const ChartOption = {
  color: [ '#1890FF', '#2FC25B', '#FACC14', '#8543E0' ],
  legend: {
    data: [ '耕整作业', '机播作业', '植保作业', '机收作业' ],
    textStyle: {
      color: '#fff',
    },
    x: 'center',
    top: '10%',
  },
  xAxis: {
    type: 'category',
    data: [ '2020', '2021', '2022', '2023' ],
    axisTick: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      interval: 0,
      color: '#ECF0FF',
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      show: false,
      interval: 0,
      color: '#ECF0FF',
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(159, 159, 159, 0.22)',
      },
    },
  },
  grid: {
    bottom: '20%',
  },
  series: [
    {
      name: '耕整作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '机播作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '植保作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '机收作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
  ],
};
export const btlxOption = {
  color: [ '#18FF1F', '#C2AB2F', '#FA14A9', '#43E0DA' ],
  legend: {
    data: [ '耕整作业', '机播作业', '植保作业', '机收作业' ],
    textStyle: {
      color: '#fff',
    },
    x: 'center',
    top: '10%',
  },
  xAxis: {
    type: 'category',
    data: [ '2020', '2021', '2022', '2023' ],
    axisTick: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      interval: 0,
      color: '#ECF0FF',
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      show: false,
      interval: 0,
      color: '#ECF0FF',
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(159, 159, 159, 0.22)',
      },
    },
  },
  grid: {
    bottom: '20%',
  },
  series: [
    {
      name: '耕整作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '机播作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '植保作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
    {
      name: '机收作业',
      type: 'bar',
      barWidth: 10,
      data: [ 0, 0, 0, 0 ],
    },
  ],
};
let fwqkData = [
  { value: 0, name: '耕整', percen: 0 },
  { value: 0, name: '机插', percen: 0 },
  { value: 0, name: '机收', percen: 0 },
  { value: 0, name: '植保', percen: 0 },
];
export const PieOption = {
  color: [ '#1890FF', '#2FC25B', '#FACC14', '#8543E0' ],
  title: {
    text: '0亩',
    subtext: '总作业量',
    x: 'center',
    y: 'center',
    textAlign: 'center',
    top: '34%',
    left: '47%',
    textStyle: {
      color: '#fff',
      fontSize: 14,
      fontWeight: 400,
    },
    subtextStyle: {
      color: '#fff',
      fontSize: 16,
      fontWeight: 400,
    },
  },
  labelLine: {
    show: false,
  },
  tooltip: {
    show: false,
  },
  legend: {
    //  orient: 'vertical',
    icon: 'circle',
    left: 'center', // 修改为居中
    bottom: '10%',
    height: 60,
    // itemWidth: 30,
    itemGap: 10,
    textStyle: {
      rich: {
        a: {
          color: '#fff',
          fontSize: 16,
          padding: [ 0, 3, 0, 0 ],
        },
        b: {
          color: '#fff',
          fontSize: 16,
          padding: [ 0, 3, 0, 0 ],
        },
      },
    },
    formatter: function (name) {
      var target, unit;
      for (var i = 0, l = fwqkData.length; i < l; i++) {
        if (fwqkData[i].name == name) {
          target = fwqkData[i].value;
          unit = fwqkData[i].unit;
        }
      }
      return `{a| ${ name }}{b|${ target }%}`;
    },
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: [ '40%', '55%' ],
      center: [ '48%', '40%' ], // 修改为居中
      avoidLabelOverlap: true,
      labelLine: {
        show: false,
      },
      data: fwqkData,
      label: {
        show: false,
      },
    },
  ],
};
let btlxData = [
  { value: 0, name: '耕整作业', percen: 0 },
  { value: 0, name: '机播作业', percen: 0 },
  { value: 0, name: '植保作业', percen: 0 },
  { value: 0, name: '机收作业', percen: 0 },
];
export const btPieOption = {
  color: [ '#18FF1F', '#C2AB2F', '#FA14A9', '#43E0DA' ],
  title: {
    text: '0元',
    subtext: '补贴金额',
    x: 'center',
    y: 'center',
    textAlign: 'center',
    top: '34%',
    left: '47%',
    textStyle: {
      color: '#fff',
      fontSize: 14,
      fontWeight: 400,
    },
    subtextStyle: {
      color: '#fff',
      fontSize: 16,
      fontWeight: 400,
    },
  },
  labelLine: {
    show: false,
  },
  tooltip: {
    show: false,
  },
  legend: {
    //  orient: 'vertical',
    icon: 'circle',
    left: 'center', // 修改为居中
    bottom: '10%',
    height: 60,
    // itemWidth: 30,
    itemGap: 10,
    textStyle: {
      rich: {
        a: {
          color: '#fff',
          fontSize: 16,
          padding: [ 0, 3, 0, 0 ],
        },
        b: {
          color: '#fff',
          fontSize: 16,
          padding: [ 0, 3, 0, 0 ],
        },
      },
    },
    formatter: function (name) {
      var target, unit, newName;
      for (var i = 0, l = btlxData.length; i < l; i++) {
        if (btlxData[i].name == name) {
          target = btlxData[i].value;
          unit = btlxData[i].unit;
          newName = name.slice(0, 2);
        }
      }
      return `{a| ${ newName }}{b|${ target }%}`;
    },
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: [ '40%', '55%' ],
      center: [ '48%', '40%' ], // 修改为居中
      avoidLabelOverlap: true,
      labelLine: {
        show: false,
      },
      data: btlxData,
      label: {
        show: false,
      },
    },
  ],
};
export const workload = [
  '0',
  '0',
  ',',
  '0',
  '0',
  '0',
  ',',
  '0',
  '0',
  '0',
  '.',
  '0',
  '0',
];
const color = {
  title: { color: '#7CDF04', fontSize: 16, fontWeight: 400 },
  contt: { color: '#4CE348', fontSize: 16, fontWeight: 400 },
  cont: {
    color: '#fff',
    fontSize: 24,
  },
};
const colorC = [ '#7CDF04','#4CE348' ];
export const jztOption = {
  color: colorC,
  title: {
    text: `${ workload.join('') }亩`,
    subtext: '总作业量',
    textAlign: 'center',
    bottom: '4%',
    left:'27%',
    textStyle: {
      color: '#fff',
      fontSize: '18',
    },
    subtextStyle: {
      color: '#fff',
      fontSize: '18',
    },
  },
  series: [
    {
      name: '占比',
      type: 'funnel',
      sort: 'ascending',
      left: '10%',
      right: '55%',
      top: '10%',
      bottom: '33%',
      // y: 100,
      // y2: 100,
      // width: "50%",
      min: 0,
      max: 50000000,
      minSize: '0%',
      maxSize: '100%',
      gap: 15,
      data: [
        { id: 'title', value: 0, name: '0%', tit: '对外服务' },
        { id: 'contt', value: 0, name: '0%', tit: '对内服务' },
      ].sort(function (a, b) {
        return a.value - b.value;
      }),
      label: {
        formatter: params => {
          console.log(params);
          return (
            `{title|${
              params.data.tit
            } ${
              params.name
            }} {cont|${
              params.value
            }亩` +
            '}'
          );
        },
        rich: {
          title: {
            color: '#7CDF04',
            fontSize: 16,
            fontWeight: 400,
          },
          cont: {
            color: '#fff',
            fontSize: 24,
          },
        },
      },
      itemStyle: {
        normal: {
          borderWidth: 0,
          shadowBlur: 20,
          shadowOffsetX: 0,
          shadowOffsetY: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};

export const btqkOption = {
  color: [ '#12E7E8', '#4DA3FF' ],
  title: {
    text: `${ workload.join('') }元`,
    subtext: '总补贴',
    textAlign: 'center',
    bottom: '4%',
    right: '33%',
    textStyle: {
      color: '#fff',
      fontSize: '18',
    },
    subtextStyle: {
      color: '#fff',
      fontSize: '18',
    },
  },
  series: [
    {
      name: '占比',
      type: 'funnel',
      sort: 'ascending',
      left: '10%',
      right: '55%',
      top: '10%',
      bottom: '33%',
      // y: 100,
      // y2: 100,
      // width: "50%",
      min: 0,
      max: 50000000,
      minSize: '0%',
      maxSize: '100%',
      gap: 15,
      data: [
        { value: 0, name: '0%', tit: '上级补贴' },
        { value: 0, name: '0%', tit: '当地补贴' },
      ].sort(function (a, b) {
        return a.value - b.value;
      }),
      label: {
        formatter: params => {
          return (
            `{${
              params.data.id
            }|${
              params.data.tit
            } ${
              params.name
            }} {cont|${
              params.value
            }元` +
            '}'
          );
        },
        rich: color,
      },
      itemStyle: {
        normal: {
          borderWidth: 0,
          shadowBlur: 20,
          shadowOffsetX: 0,
          shadowOffsetY: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};
