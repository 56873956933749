<template>
  <div class="dry-service">
    <div class="dry-service-echart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>
<script>
import Echart from '@/components/echarts/index.vue';
export default {
  props: {
    propOptions: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    Echart,
  },
  data() {
    return {
      option: this.propOptions,
    };
  },
};
</script>

  <style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dry-service {
  position: relative;
  width: companyW(500vw);
  height: companyH(570vh);
  background: rgba(0, 20, 36, 0.4);
  // opacity: 0.74;
  .dry-service-echart {
    margin: 0 auto;
    width: companyW(500vw);
    height: companyH(570vh);
  }
}
</style>

