import * as echarts from 'echarts';
const njzl = {
  title: {
    top: 20,
    left: 20,
    show: false,
    text: '农机总量',
    textStyle: {
      color: '#fff',
      fontSize: 18,
    },
  },
  legend: {
    show: true,
    x: 'right',
    y: '15',
    itemWidth: 13.5,
    itemStyle: {
      color: '#22C6FC',
    },
    textStyle: {
      color: '#ECF0FF',
    },
  },
  xAxis: {
    type: 'category',
    data: [
      '耕整机械',
      '机插机械',
      '收割机械',
      '植保机械',
      '烘干机械',
      '加工机械',
    ],
    nameTextStyle: {
      align: 'left',
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      interval: 0,
      color: '#ECF0FF',
      textStyle: {
        fontSize: '12',
      }
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#3E5063',
      },
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(159, 159, 159, 0.22)',
      },
    },
    axisLabel: {
      show: true,
      interval: 0,
      color: '#ECF0FF',
      textStyle: {
        fontSize: '14',
      }
    },
  },
  series: [
    {
      name: '总数',
      data: [ '0', '0', '0', '0', '0', '0' ],
      barWidth: 15,
      type: 'bar',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#22C6FC' },
          { offset: 1, color: '#020B24' },
        ]),
      },
      label: {
        show: true, //是否展示
        position: 'top',
        textStyle: {
          fontWeight: 'bolder',
          fontSize: '20',
          fontFamily: '微软雅黑',
          color: '#fff',
        },
        formatter: params => {
          if (params.seriesName == '加装北斗') {
            return `${ params.data }台
${ params.data }%`;
          }
          if (params.seriesName == '购机补贴') {
            let val = params.data.toString().replace(/\d+/, function (n) {
              return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            });
            return `${ val }元`;
          }
        },
      },
    },
  ],
};
const jzbd = {
  title: {
    top: 20,
    left: 20,
    show: false,
    text: '总数',
    textStyle: {
      color: '#fff',
      fontSize: '18',
    },
  },
  legend: {
    show: true,
    x: 'right',
    y: '15',
    itemWidth: 13.5,
    itemStyle: {
      color: '#27E6AB',
    },
    textStyle: {
      color: '#ECF0FF',
    },
  },
  xAxis: {
    type: 'category',
    data: [ '耕整机械', '机插机械', '收割机械', '植保机械' ],
    nameTextStyle: {
      align: 'left',
    },

    axisTick: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      interval: 0,
      color: '#ECF0FF',
      textStyle: {
        fontSize: '18',
      }
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#3E5063',
      },

    },
    splitLine: {
      lineStyle: {
        color: 'rgba(159, 159, 159, 0.22)',
      },
    },
    axisLabel: {
      show: true,
      interval: 0,
      color: '#ECF0FF',
      textStyle: {
        fontSize: '16',
      }
    },
  },
  series: [
    {
      name: '已加装北斗总数',
      data: [ '0', '0', '0', '0', '0', '0' ],
      barWidth: 15,
      type: 'bar',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#27E6AB' },
          { offset: 1, color: '#030F30' },
        ]),
      },
      label: {
        show: true, //是否展示
        position: 'top',
        textStyle: {
          fontWeight: 'bolder',
          fontSize:20,
          fontFamily: '微软雅黑',
          color: '#fff',
        },

      },
    },
  ],
};
const gjbtPie = {
  color:[ '#1890FF','#2FC25B','#FACC14','#8543E0' ],
  title: {
    text: '123234512元',
    subtext: '购机补贴',
    x: 'center',
    y: 'center',
    textAlign: 'center',
    left: '25%',
    top: 'center',
    textStyle: {
      color: 'rgba(255, 255, 255, 1)',
      fontSize: 20,
      align: 'left',
    },
    subtextStyle: {
      color: '#fff',
      fontSize: '16',
      verticalAlign: 'middle',
    },
  },
  legend: {
    orient: 'vertical',
    top:'20%',
    left:'55%',
    align: 'left',
    itemGap: 20,
    icon: 'circle',
    textStyle: {
      color: '#ECF0FF',
      fontSize: '16',
    },
    formatter: params => {
      let newv = '';
      let njPieData =gjbtPie.series[0].data;

      njPieData.forEach(v => {
        if (v.name == params) {
          let bfb = Math.floor((v.value / v.count) * 100);
          newv = `${ params } ${ bfb }%    ${ v.value }台`;
        }
      });

      return newv;
    },
  },
  series: [
    {
      type: 'pie',
      top: '3%',
      left: '-45%',
      radius: [ '50%', '75%' ],
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: '#fff',
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 0, name: '耕种', count: 0 },
        { value: 0, name: '机插', count: 0 },
        { value: 0, name: '收割', count: 0 },
        { value: 0, name: '植保', count: 0 },
      ],
    },
  ],
};
const gjbtBar = {
  title: {
    top: 20,
    left: 20,
    show: true,
    text: '购机补贴',
    textStyle: {
      color: '#fff',
      fontSize: '14',
    },
  },
  legend: {
    show: false,
    x: 'right',
    y: '15',
    itemWidth: 13.5,
    itemStyle: {
      color: '#27E6AB',
    },
    textStyle: {
      color: '#ECF0FF',
    },
  },
  xAxis: {
    type: 'category',
    data: [ '耕整机械', '机插机械', '收割机械', '植保机械' ],
    nameTextStyle: {
      align: 'left',
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    axisLabel: {
      interval: 0,
      color: '#ECF0FF',
      textStyle: {
        color: '#fff',
        fontSize: '16',
      },

    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false,
      lineStyle: {
        color: '#3E5063',
      },
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(159, 159, 159, 0.22)',
      },
    },
    axisLabel: {
      show: false,
      interval: 0,
      color: '#ECF0FF',
    },
  },
  series: [
    {
      name: '购机补贴',
      data: [ '0', '0', '0', '0' ],
      barWidth: 15,
      type: 'bar',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#27E6AB' },
          { offset: 1, color: '#030F30' },
        ]),
      },
      label: {
        show: true, //是否展示
        position: 'top',
        textStyle: {
          fontWeight: 'bolder',
          fontSize: '14',
          fontFamily: '微软雅黑',
          color: '#fff',
        },
        formatter: params => {
          if (params.seriesName == '加装北斗') {
            return `${ params.data }台
${ params.data }%`;
          }
          if (params.seriesName == '购机补贴') {
            let val = params.data.toString().replace(/\d+/, function (n) {
              return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            });
            return `${ val }元`;
          }
        },
      },
    },
  ],
};

export { njzl, jzbd, gjbtPie, gjbtBar };
