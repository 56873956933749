import request from '@/utils/request.js';
// import axios from 'axios';
// 获取服务情况
export function getStatus(params) {
  return request({
    url:'/jobOrder/getStatus',
    method:'get',
    params:params
  });
}
// 获取作业服务类型栏数据
export function getJobOrderType(params) {
  return request({
    url:'/jobOrder/getJobOrderType',
    method:'get',
    params:params
  });
}
// 获取所有补贴情况  /subsidy/get
export function subsidy(params) {
  return request({
    url:'/subsidy/get',
    method:'get',
    params:params
  });
}
// 获取作业服务补贴栏数据  jobOrder/getJobOrderSubsidy
export function getJobOrderSubsidy(params) {
  return request({
    url:'/jobOrder/getJobOrderSubsidy',
    method:'get',
    params:params
  });
}
// 获取作业服务抬头数据  /jobOrder/getMain
export function getMain(params) {
  return request({
    url:'/jobOrder/getMain',
    method:'get',
    params:params
  });
}
// 获取作业订单  /jobOrder/get?jobType=耕整作业&pageNum=1&pageSize=1
export function jobOrder(params) {
  return request({
    url:'/jobOrder/getFinish',
    method:'get',
    params:params
  });
}

// 获取作业能力栏数据  /jobOrder/getDayCount
export function getDayCount(params) {
  return request({
    url:'/jobOrder/getDayCount',
    method:'get',
    params:params
  });
}
// 获取期望能力  jobOrder/getExpectOrder
export function getExpectOrder(params) {
  return request({
    url:'/jobOrder/getExpectOrder',
    method:'get',
    params:params
  });
}
// 对比获取上年情况 jobType=机插作业
export function getContrastedCondition(params) {
  return request({
    url:'/jobOrder/getContrastedCondition',
    method:'get',
    params:params
  });
}
// 根据年份获取农机调度累计订单量 /jobOrder/getAccumulate?year=2022
export function getAccumulate(params) {
  return request({
    url:'/jobOrder/getAccumulate',
    method:'get',
    params:params
  });
}
// 驾驶舱农机总览首页获取详细数据 /agricultual/selectAgriMainByType?type=1&unit=台
export function selectAgriMainByType(params) {
  return request({
    url:'/agricultual/selectAgriMainByType',
    method:'get',
    params:params
  });
}
//获得农机数量
export function selectAgriMain(params) {
  return request({
    url:'/agricultual/selectAgriMain',
    method:'get',
    params:params
  });
}
// 获得订单数量
export function getJobOrderMain(params) {
  return request({
    url:'/jobOrder/getJobOrderMain',
    method:'get',
    params:params
  });
}
//获取待调度订单
export function selectAgriStatus(params) {
  return request({
    url:'/agricultual/selectAgriStatus',
    method:'get',
    params:params
  });
}
//已调度或已完成订单/未完成订单
export function getDispatchOrFinishOrder(params) {
  return request({
    url:'/jobOrder/getDispatchOrFinishOrder',
    method:'get',
    params:params
  });
}
//获取订单详细信息列表
export function getJobOrderInfo(params) {
  return request({
    url:'/jobOrder/getJobOrderInfo',
    method:'get',
    params:params
  });
}
///jobOrder/getJobOrderInfo?status=finish&jobType=&pageNum=1&pageSize=10
//agricultual/selectAgriStatus?status=空闲&carType=
//jobOrder/getDispatchOrFinishOrder?status=未完成&jobType=&search=&pageNum=1&pageSize=10
//118.89.29.179:8081//jobOrder/getWaitOrder?jobType=耕整作业&day=0&search=灵&pageNum=1&pageSize=10
