export const tableData = [
];

export const tableList = [
];
export const MachineList = [
 
];

export const MachineList2 = [

];
